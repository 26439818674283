.GalleryStyle {
    margin-top: 8rem;
    margin-bottom: 10rem;
    display: grid;
    width: 100%;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(12, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 4rem;
}

#image1 {
    grid-row: 1;
    grid-column: 0;
    width: 50px;
    visibility: collapse;
}

#image2 {
    grid-row: 1;
    grid-column: 2/4;
    width: 250px;
    height: 160px;
    /* box-shadow: -3px 3px 5px rgb(96, 95, 95); */
    /* animation-name: slide-right;
    animation-duration: 0.5s; */
}

#image3 {
    grid-row: 1;
    grid-column: 4/10;
}

#image3Nested {
    width: 800px;
    height: 500px;
    box-shadow: 0px 0px 10px 1px rgb(96, 95, 95);
}

#image4 {
    grid-row: 1;
    grid-column: 10/12;
    width: 250px;
    height: 160px;
    /* box-shadow: 3px 3px 5px rgb(96, 95, 95); */

}

#image5 {
    grid-row: 1;
    grid-column: 13;
    width: 50px;
    visibility: collapse;
}

#textBox {
    grid-row: 2;
    grid-column: 4/10;
}

/* @keyframes slide-right {
    from {
        margin-left: 0%;
    }

    to {
        margin-left: 30%;
    }
} */