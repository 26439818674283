h1,
h2,
h3,
h4,
h5,
h6,
q {
    font-family: "Lato", sans-serif;
}

/* Swith to regular sans-serif at smaller widths*/
@media only screen and (max-width: 960px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    q {
        font-family: Arial, Helvetica, sans-serif;
    }
}

p {
    font-family: Georgia, serif;
}

body,
html {
    height: 100%;
    color: #4a4949;
    line-height: 1.8;
}

.serif {
    font-family: Georgia, serif;
}

.sans-serif {
    font-family: "Lato", sans-serif;
}

.largeFont {
    font-size: 3rem;
}

.largeMedium {
    font-size: 1.5rem;
}

.largeSmall {
    font-size: 0.75rem;
}

.small-margin-top {
    margin-top: 1.5rem;
}

.small-margin-bottom {
    margin-bottom: 1.5rem;
}

.small-margin {
    margin: 1.5rem;
}

.small-padding {
    padding: 1.5rem;
}

/* Create a Parallax Effect */
.bgimg-1,
.bgimg-2,
.bgimg-3 {
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

/* First image (Full height) */
.bgimg-1 {
    background-image: url("../images/beach-selfie.webp");
    min-height: 100%;
}

/* Swith to smaller fist image at tablet widths*/
@media only screen and (max-width: 960px) {
    .bgimg-1 {
        background-image: url("../images/beach-selfie-medium.webp");
        min-height: 100%;
    }
}

/* Swith to smaller fist image at smaller widths*/
@media only screen and (max-width: 500px) {
    .bgimg-1 {
        background-image: url("../images/beach-selfie-small.webp");
        min-height: 100%;
    }

    #nameTag {
        top: 100%;
    }
}

/* Second image (Portfolio) */
.bgimg-2 {
    background: rgb(9, 16, 52);
    background: linear-gradient(218deg, rgba(9, 16, 52, 1) 0%, rgb(39, 68, 30) 45%, rgb(59, 127, 38) 100%);
    min-height: 400px;
}

/* hide portfolio gradient/image for smaller screens */
@media only screen and (max-width: 600px) {
    .bgimg-2 {
        display: none;
    }
}

/* only display table layout portfolio if screen is under 1700px wide */
@media only screen and (min-width: 1700px) {
    #portfolio {
        display: none;
    }
}

/* only display slider gallery  if screen is over 1700px wide */
@media only screen and (max-width: 1700px) {
    #myGallery {
        display: none;
    }
}

.w3-wide {
    letter-spacing: 10px;
}

.w3-hover-opacity {
    cursor: pointer;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1600px) {

    .bgimg-1,
    .bgimg-2,
    .bgimg-3 {
        background-attachment: scroll;
        min-height: 400px;
    }
}

/*about me section*/
em {
    font-style: normal;
}

#bio {
    padding-top: 1.5rem;
}

#bio p {
    font-size: 1.1em;
    line-height: 1.9rem;
}


/* more space between about me and testimonials for smaller screens */
@media only screen and (max-width: 600px) {
    #testimonials {
        margin-top: 4rem !important;
    }
}

.topline {
    border-top: 1.5px solid #ccc !important;
}

.quoteWrapper {
    margin-top: 3rem;
}

.quote {
    margin-bottom: 5rem;
}

.client {
    margin-bottom: 0 !important;
    font-family: Georgia, serif
}

.underlineOne {
    border-bottom: 1.5px solid #ccc;
    width: 22rem;
}

.underlineTwo {
    border-bottom: 1.5px solid #ccc;
    width: 26rem;
    float: right;
}

.underlineThree {
    border-bottom: 1.5px solid #ccc;
    width: 26rem;
}

/* make underlines on testimonials 100% width for smaller screens */
@media only screen and (max-width: 600px) {
    .underlineOne {
        border-bottom: 1.5px solid #ccc;
        width: 100%;
    }

    .underlineTwo {
        border-bottom: 1.5px solid #ccc;
        width: 100%;
        float: right;
    }

    .underlineThree {
        border-bottom: 1.5px solid #ccc;
        width: 100%;
    }
}

/* remove bio photo at smaller widths*/
@media only screen and (max-width: 960px) {
    .w3-hide-small {
        display: none;
    }
}

/* make one column at smaller widths*/
@media only screen and (max-width: 960px) {
    #bio p {
        width: 100% !important;
    }
}

/* block and center footer, smaller screens*/
@media only screen and (max-device-width: 600px) {
    .extra {
        display: none;
    }
}

@media only screen and (min-device-width: 601px) {
    .centerFooter {
        text-align: left !important;
    }
}

/*format footer*/
footer a,
footer .w3-col {
    margin-bottom: 2rem;
    text-decoration: none;
}

noscript {
    font-size: 5rem;
    font-weight: bold;
}